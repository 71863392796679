<template>
  <div class="upload-outer flex">
    <a-upload
      v-bind="$props"
      :class="{'circle': circle, 'avatar-uploader': true}"
      :show-upload-list="false"
      :before-upload="beforeUpload"
      :disabled="loading"
      @change="handleChange"
    >
      <div class="upload-innder">
        <div class="icon-box">
          <slot name="icon">
            <icon-font type="iconplus"></icon-font>
          </slot>
          <div v-if="tipText" class="ant-upload-text">{{tipText}}</div>
        </div>
      </div>
    </a-upload>

    <div class="img-box">
      <ng-image :src="imageUrl" alt="avatar" />

      <slot class="upload-mask" name="mask"></slot>

      <!-- <div v-if="removeIcon && showRemove" class="close-icon" @click="onRemoveImage"> -->
      <div v-if="imageUrl" class="close-icon" @click="onRemoveImage">
        <icon-font></icon-font>
      </div>

      <div v-if="loading" class="loading-box">
        <a-icon type="loading" />
        <div class="mask"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import {Upload as AUpload, Icon as AIcon} from 'ant-design-vue';

  let props = {...AUpload.props};

  delete props.fileList;
  delete props.beforeUpload;

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  export default {
    name: 'FormUpload',
    data() {
      return {
        loading: false,
        imageUrl: '',
        uploadName: 'file',
        showRemove: false,
      };
    },

    props: {
      value: String,
      maxSize: Number,
      tipText: String,
      removeIcon: Boolean, // 是否展示移除图片icon
      circle: Boolean,
      initHeaderUrl: String,
      reloadUploading: Boolean,
      ...props
    },

    components: {
      AUpload,
      AIcon
    },

    watch: {
      value(newVal) {
        if (!newVal) {
          this.imageUrl = '';
        }
      },

      initHeaderUrl: {
        handler(newVal) {
          this.imageUrl = newVal;
        },
        immediate: true
      },

      imageUrl: {
        handler(newVal) {
          this.showRemove = !!newVal
        },
        immediate: true
      },

      reloadUploading(newVal) {
        this.loading = newVal
      }
    },

    methods: {
      onRemoveImage(event) {
        event.stopPropagation();

        this.imageUrl = '';
        this.$emit('change', '');
      },

      handleChange({file}) {
        let isUploading = file.status === 'uploading';

        console.log(file)

        if (isUploading) {
          this.loading = true;
        } else if (file.status === 'done') {
          let response = file.response || {};

          if (response && response.code && response.code !== '0') {
            this.$message.error(response.msg);
            this.loading = false;
          } else {
            let message = response.data ? response.data.file : {};

            getBase64(file.originFileObj, imageUrl => {
              this.imageUrl = imageUrl;
              this.loading = false;
            });

            this.$emit('change', message.object_name ? message.object_name : '');
          }
        } else if (file.status === 'error') {
          this.$message.error('上传失败');
          this.loading = false;
        } else {
          this.loading = false;
        }

        this.$emit('uploading', isUploading);
      },

      beforeUpload(file) {
        const type = file.type;
        const isImage = type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg';

        if (!isImage) {
          this.$message.error('请选择图片');
        }

        const fileMaxSize = !this.maxSize || this.maxSize && (file.size / 1024 / 1024 < this.maxSize);

        if (!fileMaxSize) {
          this.$message.error(`上传文件不能大于${this.maxSize}MB`);
        }

        return isImage && fileMaxSize;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .upload-outer {
    .avatar-uploader,
    .img-box {
      flex: 1 0 0;
      height: 153px;
    }

    .img-box {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $border-color;

      > img {
        max-width: 100%;
        max-height: 100%;
      }

      .loading-box {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .anticon-loading {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .upload-content {
      display: flex;
    }

    .avatar-uploader {
      margin-right: 10px;
      width: auto;

      /deep/ {
        .ant-upload {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
          margin: 0;
        }

        svg {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

  .mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .close-icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    z-index: 1000;
  }

  // .avatar-uploader {
  //   /deep/ {
  //     .ant-upload {
  // position: relative;
  // width: 100%;
  // z-index: 10;
  // margin: 0;

  // > img {
  //   max-width: 100%;
  //   max-height: 100%;
  // }

  // &:not(.ant-upload-disabled):hover {
  //   .upload-mask {
  //     opacity: 1;
  //   }
  // }
  // }

  // .upload-mask {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   background-color: rgba($color: #000000, $alpha: 0.6);
  //   opacity: 0;
  //   transition: opacity 0.1s linear;

  //   .iconfont {
  //     position: absolute;
  //     top: 50%;
  //     left: 50%;
  //     color: #fff;
  //     transform: translate(-50%, -50%);
  //   }
  // }
  // }

  // &.circle {
  //   /deep/ .ant-upload {
  //     &,
  //     img,
  //     .mask {
  //       border-radius: 100%;
  //     }
  //   }
  // }

  // .icon-box {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  // }
  // }

  // .ant-upload-select-picture-card {
  //   i {
  //     font-size: 32px;
  //     color: #999;
  //   }

  //   .ant-upload-text {
  //     margin-top: 8px;
  //     color: #666;
  //   }
  // }
</style>
