<template>
  <div class>
    <page-title-icon class="m-bottom-md" title="音乐服务" sub="我们能为音乐人做些什么？"></page-title-icon>

    <page-progress :list="List"></page-progress>
  </div>
</template>

<script>
  import PageTitleIcon from '@/pages/components/common/PageTitleIcon.vue';
  import PageProgress from '@/pages/components/common/PageProgress.vue'
  // import NgImage from '@/components/NgImage.vue';

  const List = [
    {
      step: '01',
      title: '全球商用发行',
      desc: '除了上传Spark平台，同时与海内外三十多家商用音乐平台达成合作，覆盖日本、美国、欧洲等主流市场，团队根据音乐风格量身定制海外发行方案，传播作品，创造商用收入。'
    },
    {
      step: '02',
      title: '流媒体上架',
      desc: 'Spark团队熟悉不同国家和地区的版权相关法律法规与各大音乐平台的合作模式，管理海外发行，监测合作与收入。为优质作品获取各大平台推荐位资源，提高曝光量，提升知名度。'
    },
    {
      step: '03',
      title: '营销推广',
      desc: '全渠道线上线下资源联动推广，提高音乐人知名度，提供线下厂牌、录音棚及活动资源，打造更多优质作品。'
    },
    {
      step: '04',
      title: '作品维权',
      desc: 'Spark团队拥有经验丰富的版权管理经验，熟悉不同国家和地区的版权相关法律法规，为合作的作品保驾护航。'
    },
  ]

  export default {
    data() {
      return {
        List
      }
    },
    components: {
      PageTitleIcon,
      PageProgress
      // NgImage
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
</style>
