<template>
  <a-row class="progress">
    <template v-for="(item, index) in list">
      <a-col class="progress-item" :xs="24" :sm="24" :md="Math.floor(24 / num)" :key="index">
        <b class="font-heading-0">{{item.step}}</b>
        <b class="font-lg m-bottom-xs">{{item.title}}</b>
        <icon-font type="iconsprit" class="m-bottom-lg"></icon-font>
        <p>{{item.desc}}</p>
      </a-col>
    </template>
  </a-row>
</template>

<script>
  export default {
    data() {
      return {

      }
    },

    props: {
      list: {
        type: Array,
        default() {
          return []
        }
      },
      num: {
        type: Number,
        default: 4
      }
    },

    components: {

    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .progress {
    display: flex;
    flex-wrap: wrap;
  }

  .progress-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    background-color: #fafafa;
    border-color: #fafafa;

    > p {
      min-height: 84px;
    }

    &::before {
      content: " ";
      position: absolute;
      top: 50%;
      right: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent;
      border-left-color: inherit;
      transform: translate(100%, -50%);
      z-index: 10;
    }

    &:last-child::before {
      content: none;
    }

    &:nth-child(2n) {
      background-color: #f0f0f0;
      border-color: #f0f0f0;
    }
  }

  @media screen and(min-width: $screen-width-md) {
    .progress-item {
      padding: 1.2rem 1rem;

      // &:nth-child(4n-2),
      // &:nth-child(4n-1) {
      //   background-color: #f0f0f0;
      //   border-color: #f0f0f0;
      // }
    }
  }

  @media screen and(min-width: $screen-width-lg) {
    .progress-item {
      padding: 32px 40px;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .progress-item {
      padding: 1.2rem 1rem;

      &::before {
        content: none;
      }
    }
  }
</style>
