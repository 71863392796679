import {
  CertificateType
} from '@/config/options'
import {FormCountry} from '@/components/index';

export default {
  data() {
    return {
      name: 'supplierForm',

      form: this.$form.createForm(this, {
        name: this.name
      }),
      CertificateType,
      uploadParams: {},
      uploadName: 'file',

      fileList: [],

      submiting: false,

      checkAgree: false,

      certificateId: '1',

      submitFunc: 'Sup_companyApply',
    }
  },

  computed: {
    certificateName() {
      let result = CertificateType.filter(item => item.value === this.certificateId);
      return result[0] && result[0].label ? result[0].label : '';
    }
  },

  created() {
    this.uploadParams = {
      upload_type: 1,
      field_name: this.uploadName
    }
  },

  components: {
    FormCountry
  },

  methods: {

    onUploading(uploading) {
      this.uploading = uploading;
    },

    onChangeCertificate(options) {
      this.certificateId = options;

      this.form.setFieldsValue({
        id_number: '',
        id_photo: '',
        // id_photos_front: '',
        // id_photos_back: ''
      });
    },

    submitForms() {
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          let showReel = values.showreel;
          let showReelEmpty = Array.isArray(showReel) && showReel.every(item => item.status !== 'done');

          if (this.uploading) {
            this.$message.error(this.$t('response.uploadingNoSubmit'));
            return false;
          }

          // 验证协议
          if (values.agreement === false) {
            this.form.setFieldsValue({
              agreement: undefined
            });
            this.form.validateFields(['agreement']);
            return false;
          }

          if (showReelEmpty) {
            this.$message.error(this.$t('response.selectAgain'));
            return false;
          }

          // values.id_photos = `${values.id_photos_front},${values.id_photos_back}`
          values.showreel = Array.isArray(showReel) ? showReel.filter(item => item.status === 'done').map(({
            name,
            path
          }) => ({
            name,
            path
          })) : [];

          this.submiting = true;

          this.$axios[this.submitFunc](values).then(res => {
            let data = res.data;

            if (data && data.code == 0) {
              this.$message.success(this.$t('supplier.tipSubmitSuccessSupply'));
              this.$emit('success', data.data);
            }

            this.submiting = false;
          }).catch(() => {
            this.submiting = false;
          })
        }
      });
    },
  }
}