<template>
  <a-row class="music-advantage">
    <a-col class="flex cen-center flex-column c-item" :xs="24" :sm="24" :md="8">
      <img src="@/assets/images/supplier-footer-icon-1.png" class="m-bottom-sm icon-image" alt />
      <b class="m-bottom-sm font-heading-2">公平</b>
      <span class="text-center">围绕市场供需关系周期性优化授权场景和定价，鼓励创作者和消费者参与正版授权，让作品获得最大化的传播和收益。</span>
    </a-col>
    <a-col class="flex cen-center flex-column c-item" :xs="24" :sm="24" :md="8">
      <img src="@/assets/images/supplier-footer-icon-2.png" class="m-bottom-sm icon-image" alt />
      <b class="m-bottom-sm font-heading-2">透明</b>
      <span class="text-center">标准定价，自动传输，让作品的授权数据清晰可见。区块链存证，保全版权权益。</span>
    </a-col>
    <a-col class="flex cen-center flex-column c-item" :xs="24" :sm="24" :md="8">
      <img src="@/assets/images/supplier-footer-icon-3.png" class="m-bottom-sm icon-image" alt />
      <b class="m-bottom-sm font-heading-2">便捷</b>
      <span class="text-center">试听挑选，付费授权，下载使用，三步完成音乐授权。遇到挑歌难题联系客服，资深编辑协助选曲。</span>
    </a-col>
  </a-row>
</template>

<script>

  export default {
    data() {
      return {
      }
    },
    components: {
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .music-advantage {
    .c-item {
      padding: 60px 17px;
    }

    .icon-image {
      width: 72px;
      height: 72px;
    }
  }

  @media screen and (min-width: $screen-width-lg) {
    .music-advantage {
      .c-item {
        padding: 60px 54px;
      }
    }
  }

  @media screen and (max-width: $screen-width-md) {
    .music-advantage {
      .c-item {
        padding: 30px 17px;
      }
    }
  }
</style>
