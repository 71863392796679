<template>
  <a-form
    :form="form"
    :label-col="{ span: 24}"
    :wrapper-col="{ span: 24 }"
    class="ng-form m-top-md"
  >
    <div class="flex cen-center m-bottom-lg">
      <page-title-icon title="申请成为音乐人"></page-title-icon>
    </div>

    <!-- 国籍 -->
    <a-form-item :label="$t('form.nation')">
      <form-country
        wrapClassName="ant-select-normal"
        size="large"
        rank="first"
        :placeholder="$t('form.pleaseSelect')"
        v-decorator="['nation_id', { rules: [$validate.required], initialValue: '' }]"
      ></form-country>
    </a-form-item>

    <a-form-item :label="$t('form.addressPlace')">
      <form-country
        wrapClassName="ant-select-normal"
        size="large"
        changeOnSelect
        expandTrigger="hover"
        :placeholder="$t('form.operationPlaceHolder')"
        v-decorator="['address_id', { rules: [$validate.required], initialValue: '' }]"
      ></form-country>
    </a-form-item>

    <!-- 艺名 -->
    <a-form-item label="艺名">
      <a-input
        size="large"
        placeholder="请输入艺名"
        v-decorator="['stage_name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <!-- 姓名 -->
    <a-form-item :label="$t('form.realName')">
      <a-input
        size="large"
        placeholder="请输入与身份证/护照相符的姓名"
        v-decorator="['name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <!-- <a-form-item label="手机号">
      <a-input
        size="large"
        placeholder="请输入手机号"
        v-decorator="['phone', { rules: [$validate.required, $validate.phone], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>-->

    <a-form-item :style="{display: 'none'}">
      <a-input v-decorator="['id_type', { initialValue: defaultCertificatType }]"></a-input>
      <!-- <a-select
          :options="CertificateType"
          @change="onChangeCertificate"
          v-decorator="['id_type', { rules: [$validate.required], initialValue: '1' }]"
      ></a-select>-->
    </a-form-item>

    <a-form-item label="身份验证">
      <a-input
        size="large"
        placeholder="请输入本人身份证号码/护照号码"
        v-decorator="['id_number', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <!-- 证件类型 -->
    <a-form-item label="上传身份证明">
      <form-upload
        class="upload-box w-100"
        accept="image/jpg, image/jpeg, image/png"
        list-type="picture-card"
        :action="$axios.UploadFile"
        :data="uploadParams"
        :tip-text="$t('form.uploadFrontCertTip', {name: certificateName})"
        :name="uploadName"
        @uploading="onUploading"
        :max-size="$config.UploadLicenseMaxSize"
        v-decorator="['id_photo', { rules: [$validate.uploadRequired], initialValue: '' }]"
      ></form-upload>
    </a-form-item>

    <!-- 邮箱 -->
    <a-form-item :label="$t('form.email')">
      <a-input
        size="large"
        :placeholder="$t('form.emailToVerifyPlaceholder')"
        v-decorator="['email', { rules: [$validate.required, $validate.email], initialValue: '', validateTrigger: 'blur'  }]"
      ></a-input>
    </a-form-item>

    <!-- 上传作品 -->
    <a-form-item class="no-text-tip" :label="$t('form.uploadPro')">
      <form-upload-files
        :text="$t('form.uploadFile')"
        @uploading="onUploading"
        submitBtnType="common"
        v-decorator="['showreel', { rules: [$validate.uploadRequired], initialValue: '' }]"
      ></form-upload-files>
    </a-form-item>

    <!-- 协议 -->
    <!-- <a-form-item>
      <a-checkbox
        v-decorator="['agreement', { rules: [{required: true, message: $t('supplier.tipAgreement')}], valuePropName: 'checked'}]"
      >
        <i18n path="form.iReadAndAgree" class="section" tag="span">
          <a href="javascript:;" v-t="'form.musicianAgreement'" class="font-base"></a>
        </i18n>
      </a-checkbox>
    </a-form-item>-->

    <!-- 协议 -->
    <a-form-item :wrapper-col="{ row: 24}">
      <a-button
        size="large"
        class="submit-btn w-100"
        @click="submitForms"
        type="primary"
        :loading="submiting"
      >提交申请</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
  import supplierMixins from '@/pages/supplier/mixins';
  import {FormUploadFiles} from '@/components/index'
  import FormUpload from '@/components/FormUpload.vue';
  import {CertificateType} from '@/config/options'

  import PageTitleIcon from '@/pages/components/common/PageTitleIcon.vue';

  export default {
    data() {
      return {
        name: 'supplierForm',

        submitFunc: 'Sup_musicianApply',

        defaultCertificatType: CertificateType[0].value
      }
    },

    components: {
      FormUpload, FormUploadFiles,
      PageTitleIcon
    },

    mixins: [supplierMixins]
  }
</script>

<style lang="scss" scoped>
  /deep/ {
    .ant-form-item-label {
      font-weight: bold;
    }
  }
</style>
