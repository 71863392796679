<template>
  <div class="supplier-content">
    <template v-if="state === $var.Process.START">
      <div class="flex flex-column cen-center top-content m-bottom-lg">
        <h1 class="title font-heading-0 m-bottom-sm" v-t="'supplier.startTitle'"></h1>
        <p class="section m-bottom-lg text-center font-md" style="width: 300px;">
          <i18n path="supplier.startDescribe" tag="span">
            <br />
          </i18n>
        </p>
        <div class="btn-group">
          <a-button
            class="btn"
            type="primary"
            size="large"
            @click="onClickUpplier($var.SupplierType.MUSICIAN)"
            v-t="'supplier.enterInstance'"
          ></a-button>
        </div>
      </div>

      <div class="supplier-extra container">
        <musician-partner class="m-top-60" :list="detailInfo.musicians || []"></musician-partner>
        <supplier-lastest-buy class="m-top-60" :list="detailInfo.sales_list || []"></supplier-lastest-buy>
        <music-server class="m-top-60"></music-server>
      </div>

      <div class="back-grey m-top-60">
        <supplier-footer class="container"></supplier-footer>
      </div>
    </template>

    <div v-else-if="state === $var.Process.DOING" class="forms-box container">
      <musician-record class="m-bottom-lg"></musician-record>
    </div>
  </div>
</template>

<script>
  import MusicianRecord from './components/MusicianRecord.vue';
  import MusicianPartner from './components/MusicianPartner.vue';
  import SupplierLastestBuy from './components/SupplierLastestBuy.vue';
  import MusicServer from './components/MusicServer.vue';
  import SupplierFooter from './components/SupplierFooter.vue';

  // import SupplierForm from './components/SupplierForm';
  export default {
    data() {
      return {
        /**
         * start 开始
         * doing 进行中
         * finished 完成
         */
        state: this.$var.Process.START,
        supplierType: this.$var.SupplierType.MUSICIAN,

        detailInfo: {}
      }
    },

    created() {
      this.getInfo();
    },

    components: {
      MusicianRecord,
      MusicianPartner,
      SupplierLastestBuy,
      MusicServer,
      SupplierFooter
    },
    methods: {
      onClickUpplier(type) {
        this.state = this.$var.Process.DOING;
        this.supplierType = type;
      },

      onSuccess() {
        setTimeout(() => {
          this.state = this.$var.Process.FINISHED;
        }, 1000);
      },

      getInfo() {
        this.$store.commit('changeContentLoading', true);

        this.$axios.Supplier_page().then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.detailInfo = data.data ? data.data : {};
          }

          this.$store.commit('changeContentLoading', false);
        }).catch(() => {
          this.$store.commit('changeContentLoading', false);
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .supplier-content {
    .top-content {
      height: 298px;
      background-color: $black-6;
    }

    .forms-box {
      margin-top: 40px;
      margin-bottom: 70px;

      .supplier-form {
        .ng-form-box {
          width: 630px;
          margin: 0 auto;
        }
      }
    }
  }
</style>
