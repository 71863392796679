<template>
  <div class>
    <page-title-icon class="m-bottom-md" title="最新售卖" sub="来看看近期创作者们热衷哪些音乐"></page-title-icon>

    <a-row :gutter="{xs: 10, sm: 10, md: 20}">
      <a-col
        v-for="(item, index) in newList"
        class="m-bottom-lg"
        :xs="24"
        :sm="24"
        :md="6"
        :key="index"
      >
        <div class="flex flex-column info-box m-bottom-sm w-100">
          <span class="price font-bold font-heading-2 m-bottom-xs">￥{{item.price | toPrice}}</span>
          <span class="ellipsis" :title="item.supplier">{{item.case_name}}</span>
        </div>
        <router-link
          class="font-color-base flex cen-space pos-relative"
          :to="`/musicDetail/${item.music_id}`"
        >
          <div class="music-name left flex cen-start">
            <icon-font type="iconplay-fff" class="m-right-xs"></icon-font>
            <span class="m-right-xs ellipsis">{{item.owner}}</span>
            <span class="m-right-xs">|</span>
            <span class="ellipsis">{{item.name}}</span>
          </div>
          <div class="more-icon">
            <icon-font type="iconangle-right"></icon-font>
          </div>
        </router-link>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import PageTitleIcon from '@/pages/components/common/PageTitleIcon.vue';
  // import NgImage from '@/components/NgImage.vue';

  export default {
    data() {
      return {
      }
    },

    props: {
      list: {
        type: Array,
        default() {
          return []
        }
      }
    },

    computed: {
      newList() {
        return this.list && this.list.length > 0 ? this.list : this.$root.getDefaultArray(4);
      }
    },

    components: {
      PageTitleIcon,
      // NgImage
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .info-box {
    width: 100%;
    height: 106px;
    padding: 20px;
    color: #fff;
    background-color: #2e3557;
  }

  .music-name {
    width: 100%;
    padding-right: 40px;
  }

  .more-icon {
    position: absolute;
    bottom: 0;
    right: 0;
  }
</style>
