<template>
  <div class="partner">
    <page-title-icon class="m-bottom-md" title="音乐合作伙伴" sub="他们已入驻Spark"></page-title-icon>

    <a-row :gutter="{xs: 10, sm: 10, md: 20}">
      <a-col
        v-for="(item, index) in newList"
        class="m-bottom-lg"
        :xs="12"
        :sm="12"
        :md="4"
        :key="index"
      >
        <router-link class="img-box m-bottom-sm block" :to="`/supplierInfo/${item.user_id}`">
          <ng-image class="w-100 img" :src="item.profile_photo_url" alt></ng-image>
        </router-link>
        <b class="ellipsis">{{item.name}}</b>
        <div class="ellipsis">{{item.desc}}</div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  import PageTitleIcon from '@/pages/components/common/PageTitleIcon.vue';
  // import NgImage from '@/components/NgImage.vue';

  export default {
    data() {
      return {
      }
    },

    props: {
      list: {
        type: Array,
        default() {
          return []
        }
      }
    },

    computed: {
      newList() {
        return this.list && this.list.length > 0 ? this.list : this.$root.getDefaultArray(6);
      }
    },

    components: {
      PageTitleIcon,
      // NgImage
    },
    methods: {

    }
  }
</script>

<style lang="scss" scoped>
  .img-box {
    width: 100%;
    height: 0px;
    padding-bottom: 100%;
    overflow: hidden;
    margin: 0;
    position: relative;

    .img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
</style>
