<template>
  <div class="musician-form">
    <div class="text-center">
      <i18n
        path="supplier.doingTitle"
        class="title m-bottom-xs font-heading-0"
        tag="h1"
      >{{$t(`supplierType.${supplierType}`)}}</i18n>
      <i18n
        path="supplier.doingDescribe"
        class="section m-bottom-lg"
        tag="p"
      >{{$t(`supplierType.${supplierType}`)}}</i18n>
    </div>

    <page-progress :list="List"></page-progress>

    <div class="ng-form-box">
      <musician-forms @success="onSuccess"></musician-forms>
    </div>
  </div>
</template>

<script>
  import MusicianForms from './MusicianForms.vue'
  import PageProgress from '@/pages/components/common/PageProgress.vue'
  // import NgImage from '@/components/NgImage.vue';

  const List = [
    {
      step: '01',
      title: '拥有一份完整的作品履历',
      desc: '我们为每位音乐人提供充分展示的平台，并协助梳理和上传所有过往的作品，打造专业而有识别度的音乐人名片。'
    },
    {
      step: '02',
      title: '音乐作品可获得多样收益',
      desc: '我们拓宽了音乐变现的渠道，除了推荐影视、游戏、动画等定制项目外，还帮助音乐人出售成品音乐的使用授权。'
    },
    {
      step: '03',
      title: '与跨国界音乐人分享合作',
      desc: '我们推动海内外音乐人的交流，既有针对项目的共同创作，也有跨国的合作演出，更可以认识独树一帜的艺术家。'
    },
    {
      step: '04',
      title: '加密音频流技术保护作品',
      desc: '我们独立研发并获国家专利的加密技术，让每首上传的音乐得到切实保护，并在作品交付过程中维护音乐人权益。'
    },
  ]

  export default {
    data() {
      return {
        supplierType: this.$var.SupplierType.MUSICIAN,
        List
      }
    },
    components: {
      MusicianForms,
      PageProgress
    },
    methods: {
      onSuccess() {

      }
    }
  }
</script>

<style lang="scss" scoped>
  .progress-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 32px 40px;
    background-color: #fafafa;
    border-color: #fafafa;

    > p {
      min-height: 84px;
    }

    // &::before:not(:last-child) {
    &:not(.last)::before {
      content: " ";
      position: absolute;
      top: 50%;
      right: 0;
      border-width: 10px;
      border-style: solid;
      border-color: transparent;
      border-left-color: inherit;
      transform: translate(100%, -50%);
      z-index: 10;
    }
  }

  .ng-form-box {
    padding-top: 60px;
  }

  @media screen and(min-width: $screen-width-md) {
    .progress-item {
      &:nth-child(2n) {
        background-color: #f0f0f0;
        border-color: #f0f0f0;
      }
    }

    .ng-form-box {
      width: 530px;
      margin: 0 auto;
    }
  }

  @media screen and(max-width: $screen-width-md) {
    .progress-item {
      padding: 1.2rem 1rem;

      &:nth-child(4n-2),
      &:nth-child(4n-1) {
        background-color: #f0f0f0;
        border-color: #f0f0f0;
      }
    }
  }
</style>
